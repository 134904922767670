
.buttonPay:hover .payWithCard, .buttonPay:hover .payWithApple, .buttonPay:hover .payWithTabby {
    fill: #fff !important
}

.payWithCard {
    fill: #17a2b8;
}

.payWithApple {
    fill: #000000
}

.payWithTabby {
    fill: #28a745
}